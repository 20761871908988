.Team {
  position: static;
  margin-top: 8rem;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  gap: 6rem;
  .info {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 3rem;
    div {
      &:first-of-type {
        border-right: 1px dotted rgba($color: #000000, $alpha: 0.4);
        padding-right: 10rem;
      }
      &:nth-of-type(2) {
        padding-left: 5rem;
      }
    }

    h3 {
      font-family: altera;
      font-weight: 700;
    }
  }
  h1 {
    border-bottom: 1px dotted rgba($color: #000000, $alpha: 0.4);
    color: rgba($color: #000000, $alpha: 0.7);
    font-family: altera;
    font-weight: 800;
  }
}

@media (max-width: 768px) {
  .Team {
    flex-direction: column;
    .info {
      flex-direction: column;
      div {
        &:first-of-type {
          border-right: none;
          padding-right: 0;
          border-bottom: 1px dotted rgba($color: #000000, $alpha: 0.4);
          padding-bottom: 5rem;
        }
        &:nth-of-type(2) {
          border: none;
          padding: 0;
          margin-top: 1rem;
        }
      }
      h3 {
        font-weight: 700;
      }
    }
  }
}
