header {
  width: 100vw;
  position: absolute;
  z-index: 2;
  background-color: rgba(238, 235, 231, 0.3);
  nav {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1rem 3rem;

    .logo {
      width: 9rem;
    }
    .nav-menu {
      display: flex;
      align-items: center;
      gap: 2rem;
      margin-top: 1rem;
      height: 3rem;
      li {
        &:nth-of-type(2) {
          padding: 0.5rem;
        }

        h2 {
          font-size: 1.2rem;
          font-weight: 500;
          color: black;

          &:hover {
            opacity: 0.5;
            border-bottom: 1px solid black;
          }
        }
        a {
          svg {
            font-size: 1.5rem;
            margin-bottom: 5px;
          }
        }
        .active {
          h2 {
            border-bottom: 1px solid black;
            opacity: 0.6;
          }
        }
      }
    }
    .projectsList {
      position: absolute;
      top: 5rem;
      right: 12%;
      background-color: rgba(255, 255, 255, 0.6);
      padding: 1rem 1.5rem;

      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 1rem;

      font-family: altera;
      font-weight: 700;
      li {
        text-align: center;

        a {
          color: black;
          &:hover {
            cursor: pointer;
            opacity: 0.5;
          }
        }
      }
      sub {
        margin-left: 2rem;
      }
    }
    .hamburger {
      display: none;
      cursor: pointer;
      svg {
        font-size: 1.5rem;
      }
    }
    .side-menu {
      background-color: rgb(238, 235, 231);
      position: absolute;
      top: 5rem;
      right: 0;
      padding: 0.8rem 5rem 0.8rem 0;
      border-radius: 5px 0 0 5px;
      border: 1px solid rgb(0, 0, 0);
      .side-projects {
        font-family: altera;
        font-weight: 700;
      }
      ul {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        list-style: none;
        gap: 0.5rem;
        li {
          a {
            text-decoration: none;
            color: rgb(0, 0, 0, 0.7);
            font-weight: 800;
          }
        }
      }
      .side-menu-list {
        li {
          h2 {
            &:hover {
              opacity: 0.5;
              border-bottom: 1px solid rgba($color: #000000, $alpha: 0.5);
            }
          }
          p {
            &:hover {
              opacity: 0.5;
              border-bottom: 1px solid rgba($color: #000000, $alpha: 0.5);
            }
          }
        }
      }
    }
  }
}

@media (max-width: 768px) {
  header {
    nav {
      .nav-menu {
        display: none;
      }
      .hamburger {
        display: block;
      }
    }
  }
}
