@font-face {
  font-family: altera;
  src: url(/fonts/Altera.ttf);
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
body {
  background-color: #eeebe7;
  overflow-x: hidden;
}

ul {
  list-style: none;
}

a {
  text-decoration: none;
  color: unset;
  &:hover {
    cursor: pointer;
  }
}
