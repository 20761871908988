.Contact {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 3rem;

  position: static;
  margin-top: 8rem;

  .contact {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-bottom: 2rem;
    border-bottom: 1px dotted rgba($color: #000000, $alpha: 0.5);
    .contactImages {
      margin-bottom: 1rem;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      img {
        width: 10%;
        height: 25rem;
        margin-left: 0.5rem;
        -webkit-filter: grayscale(100%); /* Safari 6.0 - 9.0 */
        filter: grayscale(100%);
        &:hover {
          filter: none;
        }
      }
    }
    a {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
  .address {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    .map {
      width: 40%;
    }
  }
}

@media (max-width: 768px) {
  .Contact {
    .contact {
      .contactImages {
        img {
          height: 10rem;
        }
      }
    }
    .address {
      .map {
        width: 80%;
      }
    }
  }
}
